import React from 'react';
import logo from '../../images/estudologia-white-logo.svg';

const DefaultTerm = () => {
  return (
    <div className="terms__screen">
      <div className="terms__brand">
        <img
          alt="Logo Estudologia"
          src={logo}
        />
      </div>
      <div className="terms__container">
        <h1>Termos de uso e Política de privacidade</h1>

        <p>
          1. O <a href={`${window.location.origin}`}>{'<site>'}</a>(“Website”) é pertencente e operado pela <strong>ESTUDOLOGIA SOLUÇÕES INOVADORAS PARA EDUCAÇÃO,</strong> pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº
          40.432.531/0001-78, com sede no SCN Quadra 05, Brasília Shopping and Towers, Bloco A, Sala 118, CEP: 70.715-900. Você pode entrar em contato conosco pelo e-mail {'<e-mail>'}.
        </p>
        <p>2. A ESTUDOLOGIA, por meio do Website e do Aplicativo, provê uma plataforma educacional para alunos, professores e demais atuantes do ecossistema educacional.</p>
        <p>
          3. Estes Termos de Uso, em conjunto com os demais contratos e a nossa <strong>Política de Privacidade</strong>, estabelecem os termos e condições aplicáveis à utilização de nosso Website. É muito importante que Você leia e compreenda estas
          regras, bem como outras regras que possam ser aplicáveis, incluindo aquelas advindas da Lei nº 8.078/1990 (“Código de Defesa do Consumidor”).
        </p>
        <p>
          A Estudologia desenvolveu esta Política de Privacidade com o intuito de fazer com que o Usuário conheça o tratamento que dá aos seus Dados Pessoais e Dados Pessoais Sensíveis quando o Usuário faz o seu cadastro no Site e continua no
          processo de corretagem de imóveis e serviços relacionados (“Política de Privacidade”).
        </p>
        <p>
          5. Estes Termos e Condições de Uso podem ser alterados por nós a qualquer momento, sem prévio aviso. A última versão destes Termos e Condições de Uso sempre estará disponível no Website<a href={`${window.location.origin}`}>{'<site>'}</a>, e
          sugerimos fortemente que VOCÊ consulte eventuais atualizações de tempos em tempos. Salientamos que tais alterações serão aplicáveis aos nossos serviços desde o momento em que forem disponibilizadas no Website. Algumas condições disponíveis
          em avisos legais em outros locais do Website podem substituir ou complementar estes Termos e Condições de Uso.
        </p>
        <p>Essa Política de Privacidade contém informações legais importantes e indispensáveis à utilização do Site e recursos. Em caso de desacordo com as previsões aqui contidas, o Usuário se absterá de utilizar o Site e suas funcionalidades.</p>
        <p>
          A presente Política de Privacidade está disponível para leitura e consulta pelo Usuário a qualquer tempo no Site e por meio do link de Política de Privacidade, considerando suas atualizações e ajustes para melhor detalhar e definir sempre
          que necessário nossas políticas.
        </p>

        <h2>1. Definições</h2>

        <p>Para os fins desta Política de Privacidade, as seguintes expressões terão o seguinte significado:</p>
        <p>
          a) “Dado Pessoal” “refere-se à informação relacionada à pessoa física identificada ou identificável;
          <br />
          b) “Dado Pessoal Sensível” significa o dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida
          sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
          <br />
          c) “LGPD” refere-se à Lei Geral de Proteção de Dados (Lei nº 13.709/2018), suas alterações, ajustes, adequações;
          <br />
          d) “Serviços” significam as atividades oferecidas pela Estudologia, que tem como objetivo viabilizar a corretagem de imóveis e serviços relacionados.
          <br />
          e) “ESTUDOLOGIA” significa a Estudologia empresa especializada do segmento de Análise de Dados (Data Analytics).
          <br />
          f) “Usuário” refere-se às pessoas físicas e pessoas jurídicas que poderão usar o Site, mediante aceite dos Termos e desta Política de Privacidade, e que poderão contratar os Serviços.
          <br />
          g) “Site” significa o Site online da Estudologia, bem como demais aplicativos, plataformas e ferramentas, que operam por meio de inteligência artificial, tecnologias interativas, sistemas, ferramentas de colaboração, e que viabiliza
          processos de acompanhamento de aprendizagem e inovações educacionais;
          <br />
          h) “Política de Privacidade” significa esta política de privacidade que tem como intuito regular os direitos e deveres da Estudologia e do Usuários.
          <br />
          i) “Representante” significa o diretor, gerente, preposto ou outro colaborador que detenha os poderes necessários para realizar o cadastro no Site e/ou serviços em nome da pessoa jurídica, conforme disposições legais e das regras
          societárias do Usuário.
          <br />
          j) “Termos” significam os termos de uso do site, sistemas, recursos, plataformas, sistemas de colaboração disponibilizados, inicialmente no site através do link de política de privacidade. Que poderá existir também customizado por
          departamento, sistema, solução, canal ou outro meio.
        </p>

        <h2>2. Dados coletados e forma de coleta</h2>
        <p>
          a) Dados coletados via Site/Recursos de terceiros. Conforme previsto nos Termos de Uso, alguns Dados Pessoais podem ser coletados pela Estudologia em site de terceiros e/ou fornecidos por agentes intermediários que o usuário tenha
          relacionamento, para viabilizar o cadastro do Usuário no Site ou em nossas plataformas. São eles:
          <ul>
            <li>a. Facebook</li>
            <li>b. Google</li>
            <li>c. Whatsapp/Telegram</li>
            <li>d. E-mails particulares dos agentes e/ou do próprio usuário</li>
            <li>e. Instagram</li>
            <li>f. Outras plataformas</li>
          </ul>
        </p>

        <p>
          b) Dados fornecidos pelo Usuário. Para viabilizar a atividade da Estudologia, o Usuário deverá fazer, em um primeiro momento, o seu cadastro, seja ele pelo site, pelo seu agente comercial escolhido ou até presencialmente, incluindo no
          formulário de cadastro as imagens tiradas dos seus Dados Pessoais listados a seguir, tais como (porém não limitados): Dados Pessoais Completos (RG, CPF, nome completo, email, telefone, endereço completo); Dados Bancários.
        </p>
        <p>
          c) Dados coletados automaticamente pelo Site. É possível a coleta automática pelo Site de algumas informações técnicas, incluindo mas não limitadas a, tipo de dispositivo usado para acessar o Site, IP de conexão (com data de hora de
          acesso), localização geográfica aproximada, números de identificação do seu dispositivo, informações sobre o uso do Site, o software ou navegador utilizado para acessar o Site, o sistema operacional e sua versão do dispositivo utilizado,
          entre outros.
        </p>
        <p>e) Dados de fontes públicas e de provedores privados. O Usuário reconhece que a Estudologia poderá associar os dados do Usuário com dados disponibilizados obtidos de fontes públicas e/ou fontes privadas.</p>
        <p>
          f) Cookies. A Estudologia também poderá utilizar tecnologia padrão para coletar informações dos Usuários, tais como cookies, de modo a melhorar sua experiência de navegação. O uso destas tecnologias poderá ser desativado pelo Usuário a
          qualquer tempo, de acordo com as configurações de seu dispositivo de acesso. Entretanto, se esta configuração for implementada, ressaltamos que parte das funções oferecidas pelo Site podem não funcionar corretamente.
        </p>
        <p>
          g) Preocupação na Minimização dos dados: Sempre que possível e necessário, realizaremos a minimização de dados, requerendo apenas os dados realmente necessários para cada usuário, não exigindo dados excedentes ao necessário ao processo
          requerido pelo cliente e/ou exigido pelas partes interessadas relevantes ao processo.
        </p>
        <p>
          h) Anonimização/pseudomização de Dados: Em relação a anonimização de dados, todos os recursos utilizados pela empresa utilizarão canais com criptografia (ex.: https) e sempre que possível, as soluções adquiridas terão seus dados
          criptografados em base de dados e com aplicação ou exigência de práticas de segurança da informação formalmente implementadas.
        </p>
        <p>i) Dados de Menores de Idade: Normalmente não utilizamos ou requeremos informações de menores, porém caso ocorra tal necessidade, obrigatoriamente será exigido a autorização e fornecimento por parte de responsável legal.</p>
        <p>
          j) Prazos de retenção: Caso tenhamos contrato fechado, as partes terão os dados mantidos por no mínimo 5 (cinco) anos, após findados os compromissos financeiros e de entregas, podendo ter informações guardadas para históricos e pós
          atendimentos posteriores por mais tempo. Lembrando ainda que ofertas direcionadas ao perfil de consumo e/ou complementos ao objeto de aquisição poderão ser realizadas posteriormente, salvo se o consentimento for formalmente retirado pelo
          cliente/parte.
        </p>
        <p>
          k) Atividade de descarte: Se necessário, e para cumprimento legal, ou findado o processamento legal previsto, poderá ocorrer descarte de suas informações e seus dados pessoais, sendo eles armazenados em backups criptografados, excluídos
          corretamente de bases e/ou listas, bem como o expurgo seguro das informações.
        </p>
        <p>
          l) Outros processadores: Como já referenciado e será de conhecimento, outros processadores poderão estar envolvidos no processamento, tais como órgãos públicos/governamentais, instituições financeiras, corretoras, processadoras de
          pagamento, empresas de auditorias independente de certificação, consultorias técnicas especializadas, empresas fornecedoras de soluções tecnológicas inerentes ao processo, dentre outras. Todas estas empresas terão seus contratos sempre que
          aplicável/possível questionado sobre proteção de dados, e requisitos de cumprimento legal de práticas de proteção de dados e segurança da informação.
        </p>

        <h2>3. Finalidades do Tratamento</h2>
        <p>
          a) Dados necessários para uso do Site. A Estudologia utiliza os Dados Pessoais e Dados Pessoais Sensíveis para as finalidades descritas abaixo, podendo utilizar os dados também para outras finalidades associadas às atividades de Data
          Analytics para educação.
        </p>
        <p>
          b) Finalidades: Seguem as finalidades as quais poderão ser submetidos os Dados Pessoais e os Dados Pessoais Sensíveis.
          <ul>
            <li>a) Validar e atualizar as informações cadastrais dos Usuários;</li>
            <li>b) Analisar e categorizar os usuários em ranking;</li>
            <li>c) Avaliar a performance dos usuários para indicar trilhas de aprendizagem;</li>
            <li>d) Fazer levantamentos de dados estatísticos;</li>
            <li>e) Entre outros.</li>
          </ul>
        </p>

        <p>
          c) Como no site e até mesmo no provimento do serviço através de outros canais (como presencialmente, por agente intermediário utilizado pelo usuário) o assessoramento não poderá ser empregado ou atividades previstas em funcionalidades
          sistêmicas ou site sem a maioria de tais dados, sua coleta e tratamento são fundadas especialmente em tal necessidade para o cumprimento do contrato entre a Estudologia e o Usuário.
        </p>
        <p>
          d) Dados necessários para funcionalidades secundárias. Alguns dados, incluindo os Dados Pessoais, são coletados e utilizados para possibilitar o funcionamento de serviços acessórios ao Site, como informações de contato utilizadas para
          enviar ao Usuário informações importantes sobre o Site, serviços e/ou dados de acesso, seu cadastro ou outras novidades relevantes. Tendo então o usuário obrigação de fornecimento consciente e correto de dados de contato para comunicação,
          sendo de sua única responsabilidade o uso e definição desta informação.
        </p>
        <p>
          e) Dados coletados para cumprimento de obrigações legais. A Estudologia é obrigada por lei a conservar alguns dados básicos de registro de acesso à Site e sistemas, incluindo, mas não limitado, a endereço IP da conexão de origem, com data e
          hora. Tais dados são conservados pelo período mínimo de 6 meses, podendo ser sobrescritos. Eventualmente, outros dados podem ser armazenados também para cumprimento de obrigações legais e para cumprimento da regulamentação aplicável.
        </p>
        <p>
          f) Dados coletados para melhoria do Site e suporte técnico. A Estudologia poderá utilizar, por exemplo, informações técnicas coletadas do seu dispositivo para facilitar o processo de suporte e fornecimento de assistência técnica em caso de
          bug ou mal funcionamento do Site. A Estudologia utiliza também os dados coletados, os de utilização do Site e informações técnicas do seu dispositivo para melhorar o Site em si, adicionando funcionalidades ou melhorando outros aspectos dela
          e sua navegabilidade.
        </p>
        <p>
          g) Direcionamento de ofertas e publicidade. A Estudologia pode utilizar os dados coletados, incluindo os Dados Pessoais, também para direcionar ofertas e anúncios publicitários os quais a Estudologia achar que possam ser do interesse do
          Usuário. Tais ofertas e anúncios terão, sempre que possível, relação com o Serviço e com as demais atividades da Estudologia. O Usuário pode optar por impedir tais comunicações, quando sejam feitas por comunicação direta e não por sistemas
          integrados, realizando o opt-out de tais comunicações ou solicitando sua interrupção.
        </p>
        <p>
          h) Outras finalidades. A Estudologia pode utilizar os dados coletados também para outras finalidades que não sejam conflitantes ou excessivas em relação às finalidades listadas acima, sempre em conformidade com a legislação brasileira, com
          base em interesse legítimo, exceto nos casos em que prevaleçam os direitos e garantias legais do Usuário.
        </p>

        <h2>4. Comunicação e uso compartilhado</h2>
        <p>
          a) Comunicação a operadores do tratamento. A Estudologia poderá compartilhar os dados coletados por meio do Site com prestadores de serviço, subcontratados ou outros fornecedores que atuarem, sob direção da Estudologia, no tratamento dos
          Dados Pessoais e Dados Pessoais Sensíveis coletados por meio do Site, na condição de operadores do tratamento, de modo a permitir o correto fornecimento do Site aos Usuários. Tal comunicação é feita em conformidade com o disposto na Lei
          Geral de Proteção de Dados (“LGPD”), adotando os operadores todas as precauções exigidas em lei para garantia da segurança e da confidencialidade dos dados comunicados.
        </p>
        <p>
          b) Comunicação a terceiros. A Estudologia poderá compartilhar os dados coletados por meio do Site com terceiros nas situações descritas abaixo:
          <ul>
            <li>a. para a empresa responsável pelo armazenamento e processamento dos dados; caso necessário;</li>
            <li>b. para proteção dos interesses da Estudologia em qualquer tipo de conflito, inclusive demandas judiciais;</li>
            <li>e. no caso de transações e alterações societárias envolvendo a Estudologia, hipótese em que a transferência das informações será necessária para a continuidade dos serviços e entrega dos produtos; ou</li>
            <li>f. mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição, neste caso nos termos do artigo 10, §3º do Marco Civil da Internet (Lei 12.965/2014);</li>
            <li>g. Outros.</li>
          </ul>
        </p>

        <p>
          c) Uso compartilhado. A Estudologia pode vir a realizar o uso compartilhado de dados com entes públicos, por solicitação destes, para projetos de interesse da Estudologia e/ou de interesse público, comprometendo-se a Estudologia a prestar
          aos Usuários todas as informações cabíveis, como disposto na LGPD, sempre que tal uso compartilhado ocorrer.
        </p>

        <h2>5. Transferências internacionais</h2>
        <p>
          Os Dados Pessoais e Dados Pessoais Sensíveis coletados durante a utilização do Site podem ser processados pela Estudologia, ou subcontratados, sempre nos moldes definidos pela LGPD e pela Estudologia. A Estudologia está sediada no Brasil,
          mas também pode realizar o tratamento de dados em outros países que não o Brasil. Nesse caso, garantimos que as nossas afiliadas e subcontratadas serão submetidas também às obrigações desta Política de Privacidade. Assim, ao concordar com
          esta Política de Privacidade, o Usuário concorda também que os Dados Pessoais e Dados Pessoais Sensíveis podem estar sujeitos a transferências internacionais com destino às afiliadas, subcontratados ou parceiras da Estudologia, e se declara
          ciente de todas as garantias aplicáveis ao tratamento no exterior, conforme esta Política de Privacidade.
        </p>

        <h2>6. Direitos do Titular dos Dados</h2>
        <p>
          a) Direitos do Titular. O Usuário e os Representantes reconhecem que, sendo titular dos Dados Pessoais e Dados Pessoais Sensíveis, possuem os seguintes direitos, em conformidade com a LGPD:
          <ul>
            <li>a. confirmação da existência do tratamento;</li>
            <li>b. acesso aos dados;</li>
            <li>c. correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>d. anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</li>
            <li>e. portabilidade dos dados a outro fornecedor de serviço ou produto, resguardados os segredos comerciais e industriais da Estudologia;</li>
            <li>f. eliminação dos dados tratados com o consentimento do titular, exceto nas hipóteses de guarda legal e outras dispostas em lei;</li>
            <li>g. todas as informações pertinentes, como exigido por lei, disponibilizadas nesta Política de Privacidade;</li>
            <li>h. revogação do consentimento, quando o tratamento se basear nele.</li>
          </ul>
        </p>

        <p>b) Forma e condições de exercício. Os direitos mencionados acima serão garantidos e poderão ser exercidos nos exatos termos descritos na LGPD.</p>
        <p>
          c) Exclusão de dados. A exclusão dos dados armazenados pela Estudologia poderá ser requisitada, nas condições estabelecidas pela LGPD, por meio do e-mail contato@estudologia.com.br. A Estudologia declara-se comprometida a empreender os
          melhores esforços para atender a todos os pedidos de exclusão, quando cabíveis, o mais breve possível. Tal exclusão, quando abranger dados necessários para o funcionamento do Site e/ou para a prestação dos Serviços, acarretará também a
          rescisão dos Termos e a exclusão do cadastro do Usuário se assim for possível e não impactar nenhum processamento legal ou regulamentar. Mesmo nesse caso, a Estudologia poderá manter os dados não sensíveis armazenados, sem sujeitá-los ao
          tratamento ativo, de modo a cumprir com suas obrigações legais.
        </p>

        <h2>7. Responsáveis pelo Tratamento</h2>
        <p>
          O tratamento dos dados coletados é realizado pela Estudologia e/ou por terceiros autorizados pela Estudologia, sempre sob orientação da Estudologia. Toda e qualquer solicitação, reclamação, pedido de informação ou exercício de direitos do
          titular pode ser realizada por mensagem escrita para o e-mail contato@estudologia.com.br. Em todos os casos, o Titular de Dados, incluindo os Representantes, deverão informar, minimamente, as seguintes informações e documentos:
          <ul>
            <li>a) Nome completo do requerente;</li>
            <li>b) acesso aos dados;</li>
            <li>c) Endereço de e-mail utilizado para cadastro no Site/Processos;</li>
            <li>d) Cópia do RG e CPF;</li>
            <li>e) Descrição da solicitação.</li>
          </ul>
        </p>
        <p>
          Eventualmente, outras informações ou documentos podem ser solicitados para o atendimento da requisição e até mesmo confirmar se o solicitante é de fato o usuário, caso haja dúvida sobre a veracidade das informações prestadas ou sobre a
          identidade do Usuário outros métodos para confirmação poderão ser requeridos, até mesmo o comparecimento presencial.
        </p>

        <h2>8. Das Disposições Gerais</h2>
        <p>a) Rescisão Contratual. Esta Política de Privacidade vige desde o cadastro no Site até o seu desligamento do Site/recursos corporativos, seja ele realizado a pedido de uma das partes, por comum acordo ou por descontinuidade do Site.</p>
        <p>b) Rescisão por violação contratual ou legal. Caso o Usuário viole esta Política de Privacidade, os Termos, ou a lei vigente, a Estudologia fica autorizada, à sua discrição, a suspender ou cancelar o acesso do Usuário aos recursos.</p>
        <p>
          c) Rescisão a pedido do Usuário. Caso o Usuário solicite o encerramento do seu cadastro, sua conta será excluída no prazo de 30 (trinta) dias a contar do pedido, feito por e-mail enviado à Estudologia no contato@estudologia.com.br. Após
          este prazo, todas as informações referentes ao Usuário serão apagadas definitivamente, exceto quando a Estudologia seja obrigada, por lei, a armazenar determinados dados, ou, quando outra base legal prevista em lei for aplicável.
        </p>
        <p>
          d) Tolerância. Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento desta Política de Privacidade e/ou de prerrogativas decorrentes dele ou da lei, não constituirão novação ou renúncia, nem afetarão o
          exercício de quaisquer direitos aqui previstos, que poderão ser plena e integralmente exercidos, a qualquer tempo.
        </p>
        <p>
          e) Independência das cláusulas. Caso se perceba que uma disposição é nula, as disposições restantes desta Política de Privacidade permanecerão em pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto
          quanto possível.
        </p>
        <p>
          f) Lei aplicável. Esta Política de Privacidade é regida pela lei brasileira, sendo esta integralmente aplicável a quaisquer disputas que possam surgir sobre a interpretação ou execução desta Política de Privacidade, assim como qualquer
          outra disputa que envolva direta ou indiretamente o uso do Site pelos Usuários.
        </p>
        <p>g) Eleição de foro. As partes optam, desde já, por reconhecer a competência da Justiça Estadual de Goiás, elegendo o foro da Comarca de Brasília, Brasil, e renunciando a qualquer outro, por mais privilegiado que seja.</p>

        <h2>9. Atualizações desta Política</h2>
        <p>
          Assim como o Site, esta Política de Privacidade está sujeita a contínuo aprimoramento, de modo que os Usuários reconhecem e compreendem que esta Política de Privacidade poderá ser modificada qualquer tempo, desde que não haja vedação legal
          para tanto.
        </p>
        <p>
          Sempre que alterações importantes forem realizadas, o Usuário será notificado sobre as mudanças desta Política de Privacidade, sendo que sua versão atualizada está disponível para leitura, a qualquer momento, no site na aba de política de
          privacidade.
        </p>
        <p>Caso tenha dúvidas sobre esta Política de Privacidade e/ou sobre as práticas da Estudologia, por favor, entre em contato com no DPO:</p>
        <p>Responsável Externo: Sanclé Landim Albuquerque</p>
        <p>Registro Qualificação DPO: 5653907.20788760</p>
        <p>Registro Membro ANPPD: 8202</p>
        <p>e-mail externo: sancle.albuquerque@midnal.com.br</p>
        <p>Versão: 10 de março de 2022.</p>
      </div>
    </div>
  );
};

export default DefaultTerm;
